<template>
  <div class="container-fluid">
    <div class="row justify-content-center mt-5">
      <div class="col fixed-width-300">
        <LinkedInProfile />
      </div>
    </div>
  </div>
</template>

<script>
import LinkedInProfile from './components/LinkedInProfile.vue'

export default {
  name: 'App',
  components: {
    LinkedInProfile
  }
}
</script>

<style>

html 
{
  background-color: #f5f5f5!important;
}

#app {
  
}

.fixed-width-300 {
  max-width: 300px;
}

</style>
