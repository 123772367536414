<template>
  <div class="badge-base LI-profile-badge" data-locale="en_US" data-size="large" data-theme="light" data-type="VERTICAL" data-vanity="danielyapco" data-version="v1">
    <a class="badge-base__link LI-simple-link" href="https://sg.linkedin.com/in/danielyapco?trk=profile-badge">Daniel Yap</a>
  </div>
</template>

<script>
export default {
  name: 'LinkedInProfile',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .badge-base__link.LI-simple-link {visibility: hidden;}
 .badge-base.LI-profile-badge {text-align: center;}
 
</style>
